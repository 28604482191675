import dataLayerProjectCards from '../utils/dataLayerProjectCards';

let projectCards = document.querySelectorAll('[data-component="projectCard"]:not([data-layer-added])');

if (projectCards.length > 0) {
  let projectGrid = projectCards[0].parentNode;

  dataLayerProjectCards(projectGrid);

  [...projectCards].forEach((projectCard) => {
    //dataLayerProjectCards(projectCard);
  });
}


