
const triggers = document.querySelectorAll('[data-component=showElementHideSiblings]');
const panels = document.querySelectorAll('[data-panel]');

function handleTriggerTarget(trigger) {

  trigger.addEventListener('click', (el) => {
    let target = document.querySelector('[data-panel="' + trigger.dataset.trigger + '"]');

    console.log(trigger);

    trigger.classList.add('is-active');

    if(trigger.dataset.trigger == 'all') {
      [...panels].forEach((el) => {
        el.classList.add('is-active');
      });
    } else {
      [...panels].forEach((el) => {
        el.classList.remove('is-active');
      });
      target.classList.add('is-active');
    }

  });
}

[...triggers].forEach((el) => {
  handleTriggerTarget(el);
});
