
let ctas = document.querySelectorAll('[data-action="cta"]');
let projects =  document.querySelectorAll('[data-action="project"]');
let exploreThumbs =  document.querySelectorAll('[data-action="exploreThumb"]');
let dataLayer = window.dataLayer || [];

function ctaClick(el) {
  const innerHTML = el.target.innerHTML;
  if (innerHTML) {
    dataLayer.push({
      'event': 'call to action click',
      'e_action': innerHTML
    });
  }
}

function projectClick(el) {
  let project = el.target.closest('[data-action="project"]');
  if (project) {
    dataLayer.push({
      'event':'project click',
      'e_action':'click thumbnails',
      'ecommerce': {
        'click': {
          'actionField': {
            'list': project.dataset.layerProjectType
          },
          'products': [{
            'name': project.dataset.layerName,
            'brand': 'morton',
            'category': project.dataset.layerCategory,
            'variant': project.dataset.layerLocation,
            'position': project.dataset.position
          }]
        }
      }
    });
  }
}

function exploreThumbClick(el) {
  let exploreThumb = el.target.closest('[data-action="exploreThumb"]');
  if (exploreThumb) {
    dataLayer.push({
      'event':'project click',
      'e_action':'click thumbnails',
      'ecommerce': {
        'click': {
          'actionField': {
            'list': 'explore related projects'
          },
          'products': [{
            'name': exploreThumb.dataset.layerName,
            'brand': 'morton',
            'category': exploreThumb.dataset.layerCategory,
            'variant': exploreThumb.dataset.layerLocation,
            'position': exploreThumb.dataset.position
          }]
        }
      }
    });
  }
}

[...ctas].forEach((cta) => {
  if (window.PointerEvent) {
    cta.addEventListener('pointerdown', ctaClick);
  } else {
    cta.addEventListener('mousedown', ctaClick);
  }
});

[...projects].forEach((project) => {
  if (window.PointerEvent) {
    project.addEventListener('pointerdown', projectClick);
  } else {
    project.addEventListener('mousedown', projectClick);
  }
});

[...exploreThumbs].forEach((exploreThumb) => {
  if (window.PointerEvent) {
    exploreThumb.addEventListener('pointerdown', exploreThumbClick);
  } else {
    exploreThumb.addEventListener('mousedown', exploreThumbClick);
  }
});
