const clearInputTriggers = document.querySelectorAll('[data-component=clearInput]');
if (clearInputTriggers.length > 0) {
  [...clearInputTriggers].forEach(clearInputTrigger => {
    // custom position
    if (clearInputTrigger.dataset.right) {
      clearInputTrigger.style.right = `${clearInputTrigger.dataset.right}px`;
    }
    // clear input
    let input = clearInputTrigger.previousElementSibling;
    if (input) {
      clearInputTrigger.addEventListener('click', (e) => {
        e.preventDefault();
        input.value = "";
        if (input.dataset.projects) {
          input.placeholder = "Search Projects";
        }
        clearInputTrigger.classList.add('is-hidden');
      });
      // Handle the hide / show
      input.addEventListener('keyup', () => {
        input.value ? clearInputTrigger.classList.remove('is-hidden') : clearInputTrigger.classList.add('is-hidden');
      });
    }
  });
}
