import addListener from '../utils/addListener'
//const BeerSlider = require('../../vendor/node_modules/beerslider/BeerSlider');
const Swiper = require('../../vendor/node_modules/swiper/dist/js/swiper');
import smoothScrollTo from '../utils/smoothScrollTo';
import contextMenuDownload from '../utils/contextMenuDownload';

const swiperHeroFull = document.querySelector('.swiperHeroFull');
const iconNav = document.querySelector('.iconNav');
const primaryNavBar = document.querySelector('.navbar.global-header');
const swiperPagination = document.querySelector('.swiper-pagination');
const swiperBenefits = document.querySelectorAll('.swiper-benefits');
const benefitsTriggers = document.querySelectorAll('[data-tab]');
const tabDetailToggles = document.querySelectorAll('[data-detailtoggle]');
const breakpoints_mobile = '(max-width: 599px)';
// since the active class moves around slides, the querySelector is run dynamically
const beforeAfterCurrentSlideQuery = '.before-after .swiper-slide-active .before-after_slider';
// full height swiper and benefits swiper are init'd dynamically for reasons
// pulling these settings out so they're easier to change
const fullHeightSwiperSettings = {
  direction: 'vertical',
  slidesPerView: 'auto',
  spaceBetween: 0,
  speed: 825,
  keyboard: true,
  hashNavigation: {
    watchState: true,
  },
  mousewheel: {
    enabled: true,
    releaseOnEdges: true,
    sensitivity: 40,
  },
  grabCursor: true,
  pagination: {
    el: '.swiperHeroFull .swiper-pagination',
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '"><span class="swiper-pagination-title">' + this.slides[index].dataset.title + '</span></span>';
    },
  },
  navigation: {
    nextEl: '.chevron',
  },
  watchSlidesProgress: true,
  watchSlidesVisibility: true
};
const benefitsSwiperSettings = {
  slidesPerView: 'auto',
  grabCursor: true,
  keyboard: true,
  spaceBetween: 0,
  mousewheel: {
    enabled: true,
    releaseOnEdges: true,
    invert: true,
  },
  scrollbar: {
    el: '.swiper-scrollbar',
    hide: false,
    draggable: true,
    dragSize: 100,
    snapOnRelease: false
  },
  watchSlidesVisibility: true
};

/*-- Create Swiper Objects --*/
const relatedProjectsSwiper = new Swiper('.swiper-explore .swiper-container', {
  slidesPerView: 'auto',
  grabCursor: false,
  keyboard: true,
  spaceBetween: 30,
  navigation: {
    nextEl: '.swiper-explore .swiper-button-next',
    prevEl: '.swiper-explore .swiper-button-prev',
  },
  // breakpoints: {
  //   756: {
  //     slidesPerGroup: 2
  //   },
  //   1200: {
  //     slidesPerGroup: 4
  //   }
  // }
});

const galleryThumbs = new Swiper('.swiperHeroGallery ._thumbs .swiper-container', {
  spaceBetween: 10,
  keyboard: true,
  slidesPerView: 'auto',
  watchSlidesVisibility: true,
  watchSlidesProgress: true,
  navigation: {
    nextEl: '.swiperHeroGallery ._thumbs .swiper-button-next',
    prevEl: '.swiperHeroGallery ._thumbs .swiper-button-prev',
  }
});

const galleryHero = new Swiper('.swiperHeroGallery ._hero', {
  slidesPerView: 'auto',
  spaceBetween: 0,
  thumbs: {
    swiper: galleryThumbs
  },
  keyboard: {
    enabled: true,
    onlyInViewport: false,
  },
  lazy: {
    loadPrevNext: true,
  },
});
galleryHero.on('slideChangeTransitionEnd', galleryHeroSwiperTransitionEnd);
galleryHero.on('slideChangeTransitionStart', galleryHeroSwiperTransitionStart);
galleryHero.on('slideNextTransitionStart', galleryHeroSwiperNextTransitionStart);
galleryHero.on('slidePrevTransitionStart', galleryHeroSwiperPrevTransitionStart);
galleryHero.on('init', contextMenuDownload());

const galleryDetailThumbs = new Swiper('.swiperDetailGallery ._thumbs .swiper-container', {
  spaceBetween: 10,
  keyboard: true,
  slidesPerView: 'auto',
  draggable: false,
  watchSlidesVisibility: true,
  watchSlidesProgress: true,
  navigation: {
    nextEl: '.swiperDetailGallery ._thumbs .swiper-button-next',
    prevEl: '.swiperDetailGallery ._thumbs .swiper-button-prev',
  }
});

const galleryDetail = new Swiper('.swiperDetailGallery ._gallery', {
  slidesPerView: 1,
  spaceBetween: 40,
  thumbs: {
    swiper: galleryDetailThumbs
  },
  keyboard: {
    enabled: true,
    onlyInViewport: false,
  },
  lazy: {
    loadPrevNext: true,
  },
});

galleryDetail.on('slideChangeTransitionEnd', galleryDetailSwiperTransitionEnd);
galleryDetail.on('slideChangeTransitionStart', galleryDetailSwiperTransitionStart);
galleryDetail.on('slideNextTransitionStart', galleryDetailSwiperNextTransitionStart);
galleryDetail.on('slidePrevTransitionStart', galleryDetailSwiperPrevTransitionStart);
galleryDetail.on('init', contextMenuDownload());

const tabsSwiper = new Swiper('.tabs.swiper-container', {
  slidesPerView: 'auto',
  grabCursor: true,
  keyboard: true,
  mousewheel: {
    enabled: true,
    releaseOnEdges: true,
    invert: true
  },
  scrollbar: {
    el: '.swiper-scrollbar',
    hide: false,
    draggable: true,
    dragSize: 100,
    snapOnRelease: false
  },
  watchSlidesVisibility: true
});

const beforeAfterSwiper = new Swiper('.before-after .swiper-container', {
  allowTouchMove: false,
  preloadImages: false,
  navigation: {
    nextEl: '.before-after .swiper-button-next',
    prevEl: '.before-after .swiper-button-prev',
  },
  pagination: {
    el: '.before-after .swiper-pagination',
    type: 'fraction',
  },
  lazy: {
    loadPrevNext: true,
    loadPrevNextAmount: 2,
    loadOnTransitionStart: true
  },
  on: {
    init: beforeAfterSwiperInit,
    transitionEnd: beforeAfterSwiperTransitionEnd
  },
});


/*-- Internal variables --*/
let benefitsSlides = [];
let dataLayer = window.dataLayer || [];
let fullHeightSwiper;

/*-- Helper Functions --*/
// these should be pulled into a library. super handy stuff.
let hasClass =    (el, classToFind)   => el.classList.contains(classToFind);
let addClass =    (el, classToAdd)    => { el.classList.add(classToAdd); };
let removeClass = (el, classToRemove) => { el.classList.remove(classToRemove); };
let hasAttr =     (el, attrToCheck)   => el.hasAttribute(attrToCheck);
let setAttr =     (el, attr, val)     => { el.setAttribute(attr, val); }


function handleDetailToggle(e) {
  var targetHolder = this.previousElementSibling.previousElementSibling;
  setTimeout(function() {
    smoothScrollTo(targetHolder, targetHolder.getBoundingClientRect().top + window.scrollY - 155);
  }, 330)

  return false;
}

[...tabDetailToggles].forEach((el) => {
  if (el.getAttribute('data-type') != null && el.getAttribute('data-type') === 'noscroll') {
    // No actions here
  } else {
    el.addEventListener('click', handleDetailToggle);
  }
});

const isiOS =
        (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) ||
        (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);

class BeerSlider {
  constructor(element, isiOS, { start = "50", prefix = "beer" } = {}) {
    this.start = parseInt(start) ? Math.min(100, Math.max(0, parseInt(start))) : 50;
    this.prefix = prefix;
    // swal ios fix
    this.isiOS = isiOS;
    // added
    this.clicked = 0;
    this.imgBoundingClientRect = null;

    if (!element || element.children.length !== 2) {
      return;
    }
    this.element = element;
    this.revealContainer = this.element.children[1];
    if (this.revealContainer.children.length < 1) {
      return;
    }
    this.revealElement = this.revealContainer.children[0];
    this.range = this.addElement("input", {
      type: "range",
      class: `${this.prefix}-range`,
      "aria-label": "Percent of revealed content",
      "aria-valuemin": "0",
      "aria-valuemax": "100",
      "aria-valuenow": this.start,
      value: this.start,
      min: "0",
      max: "100",
    });
    this.handle = this.addElement("span", {
      class: `${this.prefix}-handle`,
    });
    this.onImagesLoad();
  }

  init() {
    this.element.classList.add(`${this.prefix}-ready`);
    this.setImgWidth();
    this.move();
    this.addListeners();
  }
  loadingImg(src) {
    return new Promise((resolve, reject) => {
      if (!src) {
        resolve();
      }
      const img = new Image();
      img.onload = () => resolve();
      img.onerror = () => reject();
      img.src = src;
    });
  }
  loadedBoth() {
    const mainImageSrc = this.element.children[0].src || this.element.children[0].getAttribute(`data-${this.prefix}-src`);
    const revealImageSrc = this.revealElement.src || this.revealElement.getAttribute(`data-${this.prefix}-src`);
    return Promise.all([this.loadingImg(mainImageSrc), this.loadingImg(revealImageSrc)]);
  }
  onImagesLoad() {
    if (!this.revealElement) {
      return;
    }
    this.loadedBoth().then(
      () => {
        this.init();
      },
      () => {
        console.error("Some errors occurred and images are not loaded.");
      }
    );
  }
  addElement(tag, attributes) {
    const el = document.createElement(tag);
    Object.keys(attributes).forEach((key) => {
      el.setAttribute(key, attributes[key]);
    });
    this.element.appendChild(el);
    return el;
  }
  setImgWidth() {
    this.revealElement.style.width = getComputedStyle(this.element)["width"];
    this.imgBoundingClientRect = this.element.getBoundingClientRect();
  }
  addListeners() {
    const eventTypes = ["input", "change"];
    eventTypes.forEach((i) => {
      this.range.addEventListener(i, () => {
        this.move();
      });
    });
    window.addEventListener("resize", () => {
      this.setImgWidth();
    });

    // iOS fix - https://www.w3schools.com/howto/howto_js_image_comparison.asp
    if (isiOS) {
      this.range.addEventListener("mousedown", () => {
        this.slideReady.bind(this);
      });
      window.addEventListener(
        "mouseup",
        () => {
          this.slideFinish.bind(this);
        },
        Modernizr.passiveeventlisteners ? { passive: true } : false
      );
      this.range.addEventListener("touchstart", () => {
        this.slideReady(event, this);
      });
      window.addEventListener(
        "touchend",
        () => {
          this.slideFinish(event, this);
        },
        Modernizr.passiveeventlisteners ? { passive: true } : false
      );
    }
  }
  slideReady(e, element) {
    e.preventDefault();
    this.clicked = 1;
    this.lockBodyScroll(e);
    window.addEventListener("mousemove", this.slideMove.bind(this));
    window.addEventListener("touchmove", this.slideMove.bind(this));
  }
  slideFinish(e, element) {
    element.clicked = 0;
  }
  slideMove(e) {
    var pos;
    if (this.clicked == 0) return false;
    pos = this.getCursorPos(e, this);
    if (pos < 0) pos = 0;
    if (pos > 100) pos = 100;
    this.range.value = pos;
    this.move();
  }
  getCursorPos(e, element) {
    var a,
      x = 0;
    if (element.imgBoundingClientRect.left === 0) {
      element.imgBoundingClientRect = this.element.getBoundingClientRect();
    }
    e = e.changedTouches ? e.changedTouches[0] : e;
    x = e.pageX - element.imgBoundingClientRect.left;
    x = x - window.pageXOffset;
    x = Math.round((x / element.imgBoundingClientRect.width) * 100);
    return x;
  }
  // https://github.com/sweetalert2/sweetalert2/blob/ac79cfc4b4a1f3a1e564f1fd353c520ba7a1ca3d/src/utils/iosFix.js
  lockBodyScroll(event) {
    if ((event.touches && event.touches.length && event.touches[0].touchType === "stylus") || (event.touches && event.touches.length > 1)) {
      return;
    }
    event.stopPropagation();
  }

  move() {
    this.revealContainer.style.width = `${this.range.value}%`;
    this.handle.style.left = `${this.range.value}%`;
    this.range.setAttribute("aria-valuenow", this.range.value);
  }
}

/*-- Before / After Slider Event Handlers --*/
function beforeAfterSwiperInit() {
  setTimeout(function () {
    new BeerSlider(document.querySelector(beforeAfterCurrentSlideQuery));
  }, 225);
}

function beforeAfterSwiperTransitionEnd() {
  new BeerSlider(document.querySelector(beforeAfterCurrentSlideQuery));
}


/*-- Benefits Swiper Event Handler --*/
function activateBenefitsSlide(el) {
  benefitsSlides.push(new Swiper(el, benefitsSwiperSettings));
}

// the benefits swiper is init'd if it's found in the dom, so we don't screw up our benefitsSlides data
// i'm not sure if this is necessary, or why we're storing these, it seems benefitsSlides is only written to
function initBenefitsSwiper() {
  activateBenefitsSlide('[data-career="construction"]');

  [...benefitsTriggers].forEach((trigger) => {
    trigger.addEventListener('click', (el) => {
      let slides = document.querySelector('[data-career="' + trigger.dataset.trigger + '"]');
      activateBenefitsSlide(slides);
    });
  });
}


/*-- Full Page Hero Swiper Event Handlers --*/
function heroFullSwiperTransitionStart() {
  updateFontColors();
  updateReturnButton();
}

function handleHeroFullLastSlide() {
  let shouldHaveClass = (fullHeightSwiper.progress == 1);
  if (swiperPagination)
    shouldHaveClass ?
      addClass(swiperPagination, 'last-slide-active') :
      removeClass(swiperPagination, 'last-slide-active');
}

function updateFontColors() {
  let activeSlide = fullHeightSwiper.slides[fullHeightSwiper.activeIndex];
  let classToToggle = 'contains-text-black';

  hasClass(activeSlide, classToToggle) ?
    addClassToNavs(classToToggle) :
    removeClassFromNavs(classToToggle);
}

function addClassToNavs(classToAdd) {
  addClass(primaryNavBar, classToAdd);
  addClass(swiperPagination, classToAdd);
  if (iconNav) addClass(iconNav, classToAdd);
}

function removeClassFromNavs(classToRemove) {
  removeClass(primaryNavBar, classToRemove);
  removeClass(swiperPagination, classToRemove);
  if (iconNav) removeClass(iconNav, classToRemove);
}

function updateReturnButton() {
  let classToToggle = 'show-return-button';
  let shouldHaveClass = (fullHeightSwiper.activeIndex != 0);

  shouldHaveClass ?
    addClass(swiperHeroFull, classToToggle) :
    removeClass(swiperHeroFull, 'show-return-button');
}

// the full hero swiper is init'd based on a media query
function initHeroFullSwiper() {
  fullHeightSwiper = new Swiper('.swiperHeroFull .swiper-container', fullHeightSwiperSettings);
  fullHeightSwiper.on('slideChangeTransitionStart', heroFullSwiperTransitionStart);
  fullHeightSwiper.on('progress ', handleHeroFullLastSlide);
  fullHeightSwiper.on('reachEnd ', () => { addClass(swiperPagination, 'js-position'); });

  fullHeightSwiper.init();
  fullHeightSwiper.update();
}


/*-- Gallery Hero Event Handlers --*/
function galleryHeroSwiperTransitionEnd() {
  let slide = galleryHero.slides[galleryHero.activeIndex];
  if (slide.dataset.src) {
    let iframe = slide.querySelector('iframe');
    setTimeout(() => { autoPlayVideo(iframe, slide); }, 10);
  }
}

function galleryHeroSwiperTransitionStart() {
  let activeIframe = document.querySelector('[data-status="play"]');
  if (activeIframe) activeIframe.src = '';
}

function galleryHeroSwiperNextTransitionStart() {
  if (! hasAttr(galleryHero.el, 'data-interaction')) {
    pushGalleryDataLayer('right');
    setAttr(galleryHero.el, 'data-interaction', 'true');
  }
}

function galleryHeroSwiperPrevTransitionStart() {
  if (! hasAttr(galleryHero.el, 'data-interaction')) {
    pushGalleryDataLayer('left');
    setAttr(galleryHero.el, 'data-interaction', 'true');
  }
}

/*-- Detail Gallery Event Handlers --*/
function galleryDetailSwiperTransitionEnd() {
  let slide = galleryDetail.slides[galleryDetail.activeIndex];
  if (slide.dataset.src) {
    let iframe = slide.querySelector('iframe');
    setTimeout(() => { autoPlayVideo(iframe, slide); }, 10);
  }
}

function galleryDetailSwiperTransitionStart() {
  let activeIframe = document.querySelector('[data-status="play"]');
  if (activeIframe) activeIframe.src = '';
}

function galleryDetailSwiperNextTransitionStart() {
  if (! hasAttr(galleryDetail.el, 'data-interaction')) {
    pushGalleryDataLayer('right');
    setAttr(galleryDetail.el, 'data-interaction', 'true');
  }
}

function galleryDetailSwiperPrevTransitionStart() {
  if (! hasAttr(galleryDetail.el, 'data-interaction')) {
    pushGalleryDataLayer('left');
    setAttr(galleryDetail.el, 'data-interaction', 'true');
  }
}

function autoPlayVideo(iframe, slide) {
  iframe.dataset.status = 'play';
  iframe.src = slide.dataset.src;
}

function pushGalleryDataLayer(label) {
  dataLayer.push({
    'event': 'photo carousel',
    'e_action': 'rotate carousel',
    'e_label': label
  });
}

function findAndScrollToNext(chevron) {
  if(chevron.srcElement.parentNode.parentNode.parentNode.nextSibling.nextElementSibling == undefined) {
    let scrollToNext = chevron.srcElement.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.nextSibling.nextElementSibling;
    smoothScrollTo(scrollToNext, -75);
  } else {
    let scrollToNext = chevron.srcElement.parentNode.parentNode.parentNode.nextSibling.nextElementSibling;
    smoothScrollTo(scrollToNext, 0);
  }
}

/*-- That's all! Take it away boys --*/
if(swiperHeroFull) {
  addListener(breakpoints_mobile, (query) => {
    if (query.matches) {
      const chevrons = document.querySelectorAll('.next-slide .chevron');
      const scrollDistanceElement = document.querySelector('[data-watch="nav"]');

      if(fullHeightSwiper)
        fullHeightSwiper.destroy(false, false);

      [...chevrons].forEach((chevron) => {
        chevron.addEventListener('click', findAndScrollToNext);
      });

    } else {
      initHeroFullSwiper();
    }
  });
}

if(swiperBenefits.length > 0) {
  initBenefitsSwiper();
}
