import dataLayerProjectCards from '../utils/dataLayerProjectCards';

const ajaxUrl = '/ajax/projects?';
const xhr = new XMLHttpRequest();
const projectCatalogSection = document.querySelector('.project-catalog');
const filterElement = document.querySelector('[data-component="projectCatalog"]');
let filterProjectTypeLinks = '';
if (filterElement) {
  filterProjectTypeLinks = filterElement.querySelectorAll('a[data-filter]');
}
const filterCheckBoxes = document.querySelectorAll('input[data-filter]');
const filterResults = document.querySelector('[data-content="replace"]');
const buildingUseContainers = document.querySelectorAll('[data-buildinguse]');
let loadMoreResultsButton = '';
let noMoreResults = '';
if (projectCatalogSection) {
  loadMoreResultsButton = projectCatalogSection.querySelector('[data-append-projects]');
  noMoreResults = projectCatalogSection.querySelector('[data-no-more]');
}
const desktopFilterSidebar = document.querySelector('.sidebar');
const filterResetButtons = document.querySelectorAll('[data-filter-reset]');
const filterCounters = document.querySelectorAll('[data-filter-count]');
const pageCurrentDisplay = document.querySelector('[data-display-total]');
const pageTotalDisplay = document.querySelector('[data-initial-total]');
const dataTotalResults = document.querySelector('[data-total-results]');
const totalVisibleCards = document.querySelectorAll('.project-card');

let currentUrl = window.location;
let searchParams = new URLSearchParams(currentUrl.search);
let selectedProjectType = currentUrl.pathname.split('/')[2] || '';
let isRunning = false;

// Set up vars for dataLayer stuff
let buildingUse = [];
let sidingColors = [];
let roofColors = [];
let waintscotColors = [];
let specialFeatures = [];
let squareFeet = [];
let dataLayer = window.dataLayer || [];


/*-- Reset All Filters --*/
function filterReset() {
  searchParams = '';
  dataLayer = [];

  let checkedFilters = document.querySelectorAll('input[data-filter]:checked');
  [...checkedFilters].forEach((el) => {
    el.checked = false;

  });
  rebuildSearchParams();
  pushHistory();
  pushLocalStorage();
  loadMoreButtonReset();
  setActiveFilter();

  // hide our buttons
  if (filterResetButtons) {
    [...filterResetButtons].forEach((el) => {
      el.classList.add('hidden');
    });
  }

  // Clear any search string
  const searchInputs = document.querySelectorAll('[data-projects="true"]');
  if (searchInputs) {
    Array.from(searchInputs).forEach((el) => {
      el.value = "";
      el.placeholder = "Search Projects";
    });
  }

  // get that good fresh squeezed data juice
  submitQueryAndFetchResults();
  updateFilterCount(0);
}

/*-- History and state management --*/
/**
 * Build a JSON object for use in local storage and pop history
 */
function buildStateObject(){
  let queryString = searchParams.toString() ? '?' + searchParams.toString() : '';
  let stateTitle = 'Filtered projects';
  let stateUrl = '/project-catalog' + queryString;

  if(selectedProjectType) {
    stateTitle = 'Filtered ' + selectedProjectType + ' projects';
    stateUrl = '/project-catalog/' + selectedProjectType + queryString;
  }
  return {
    selectedProjectType: selectedProjectType,
    stateTitle: stateTitle,
    stateUrl: stateUrl
  }
}

/**
 * Push to history based on current selectedProjectType and searchParams
 */
function pushHistory() {
  const state = buildStateObject();
  history.pushState(state.selectedProjectType, state.stateTitle, state.stateUrl);
}

function pushLocalStorage() {
  const state = buildStateObject();
  window.localStorage.setItem("projectCatalogState", JSON.stringify(state));
}

/**
 * Receive history pop event and handle update to the new state
 */
function popHistory(e) {
  updateSearchParams();
  updateSelectedProjectType();
  updateBuildingUses();
  updateFilterCheckboxes();
  loadMoreButtonReset(searchParams.get('page'));

  if(filterResults) {
    filterResults.innerHTML = '';
  }
  submitQueryAndFetchResults(true);
}

function updateSearchParams(params = currentUrl.search) {
  searchParams = new URLSearchParams(params);
}

/**
 * Build searchParams based on currently selected checkboxes
 */
function rebuildSearchParams() {
  let values = {};

  [...desktopFilterSidebar.querySelectorAll('input:checked')].forEach((el) => {
    if(! values.hasOwnProperty(el.name))
      values[el.name] = el.dataset.value;
    else
      values[el.name] = values[el.name] + ',' + el.dataset.value;
  });

  let queryString = Object.keys(values).map(key => key + '=' + values[key]).join('&');
  updateSearchParams(queryString);
}

function updateSelectedProjectType(projectType = currentUrl.pathname.split('/')[2]) {
  selectedProjectType = projectType;
  setActiveTab();
}

/**
 * Set active class on our tab and dropdown option
 */
function setActiveTab() {
  let selected = document.querySelectorAll('a[data-filter][data-project="'+selectedProjectType+'"]');

  [...filterProjectTypeLinks].forEach((el) => {
    el.classList.remove('is-active');
    el.parentNode.classList.remove('is-active');
  });

  [...selected].forEach((el) => {
    el.hasAttribute("data-tab") ? el.parentNode.classList.add('is-active') : el.classList.add('is-active')
  });

  // Also close dropdown if All is selected
  let all = document.querySelector('a[data-all]');
  if (all) {
    all.addEventListener('click', function() {
      const parent = all.parentNode.parentNode.parentNode;
      parent.classList.remove('is-active');
    });
  }
}


/*-- Filter interaction handling --*/
function setActiveFilter() {
  const activeDropdown = document.querySelector('.dropdown-item.is-active');
  const dropdownButton = document.querySelector('.project-filters-mobile-trigger .dropdown-trigger button');
  let dropdownText = '';
  if (dropdownButton !== null) {
    dropdownText = dropdownButton.querySelector('span');
  }
  if (activeDropdown && dropdownButton) {
    const activeDropdownText = activeDropdown.innerText;
    if (dropdownText !== '') {
      dropdownText.innerHTML = activeDropdownText;
    }
  } else {
    if (dropdownButton !== null) {
      if (dropdownText !== '') {
        dropdownText.innerHTML = "Select Building Type";
      }
    }
  }
}
/**
 * Update based on interaction with the project type dropdown option or link
 */
function handleProjectTypeSelect(e) {
  if(e.target.dataset.project === selectedProjectType) return;

  // replace project type and remove old building uses from query string
  updateSelectedProjectType(e.target.dataset.project);
  searchParams.delete('building-use[]');
  searchParams.delete('page');

  // push new history, update checkboxes, reset load more button, submit
  pushHistory();
  pushLocalStorage();
  updateBuildingUses();
  updateFilterCheckboxes();
  loadMoreButtonReset();
  setActiveFilter();

  // get that good fresh squeezed data juice
  submitQueryAndFetchResults();
  
  // If we have an initial display of totals update that on load
  const initialTotal = document.querySelector('[data-initial-total]');
  if (initialTotal) {
    const pageTotals = document.querySelector('[data-totals]');
    initialTotal.innerHTML = pageTotals.innerHTML;
  }

  // Update our pagination display on load
  setTimeout(function() { 
    const totalVisibleCards = document.querySelectorAll('.project-card');
    const dataTotalResults = document.querySelector('[data-total-results]');
    if (pageCurrentDisplay && pageTotalDisplay && dataTotalResults && totalVisibleCards) {
      
      const total = dataTotalResults.dataset.totalResults;
      pageCurrentDisplay.innerHTML = totalVisibleCards.length;
      pageTotalDisplay.innerHTML = total;
      
    }
  }, 1200);
}

/**
 * Show or hide building uses container; show and hide building use options
 */
function updateBuildingUses() {
  selectedProjectType ?
    [...buildingUseContainers].forEach((el) => el.classList.remove('is-hidden')) :
    [...buildingUseContainers].forEach((el) => el.classList.add('is-hidden'));

  let activeFilterGroup = document.querySelectorAll('.is-active[data-group]');
  [...activeFilterGroup].forEach((el) => el.classList.remove('is-active'));

  let selectedFilterGroup = document.querySelectorAll('[data-group="'+selectedProjectType+'"]');
  [...selectedFilterGroup].forEach((el) => el.classList.add('is-active'));
}

/**
 * Update all checkboxes to match our params
 */
function updateFilterCheckboxes(params = searchParams) {
  // set variable to keep track of filter count
  let filtersSelectedCount = 0;

  // unselect filters that are not in our params
  let checkedFilters = document.querySelectorAll('input[data-filter]:checked');
  [...checkedFilters].forEach((el) => {
    if(searchParams.get(el.name) !== el.value)
      el.checked = false;
  });

  // select all filters we have in our params; this ensures mobile/desktop stays in sync
  [...params].forEach((param) => {
    param[1].split(',').forEach((value) => {
      let filterItems = document.querySelectorAll('[value="'+value+'"]');
      [...filterItems].forEach((el) => el.checked = true);
      filtersSelectedCount = filtersSelectedCount + 1;
    });
  });

  // show our buttons
  if (filterResetButtons && filtersSelectedCount > 0) {
    [...filterResetButtons].forEach((el) => {
      el.classList.remove('hidden');
    });
  }

  updateFilterCount(filtersSelectedCount);
}

function updateFilterCount(count) {
  [...filterCounters].forEach((el) => {
    if(count == 1)
      el.innerHTML = '1 Filter Selected';
    else
      el.innerHTML = count + ' Filters Selected';
  });
  if (count === 0) {
    [...filterResetButtons].forEach((el) => {
      el.classList.add('hidden');
    });
  }
}

function loadMoreButtonReset(page = 1) {
  if (loadMoreResultsButton) {
    loadMoreResultsButton.dataset.page = page;
  }
}

function showLoadingIcon() {
  filterResults.innerHTML = '<div class="loader"><svg class="circular" viewBox="25 25 50 50"><circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/></svg></div>';
}

function handleFilterSelect(e) {
  let selectedFilterItems = document.querySelectorAll('[data-value="'+e.target.dataset.value+'"]');
  if (selectedFilterItems) {      
    [...selectedFilterItems].forEach((el) => el.checked = e.target.checked);

    showLoadingIcon();
    rebuildSearchParams();
    pushHistory();
    pushLocalStorage();
    updateFilterCheckboxes();
    loadMoreButtonReset();

    // get that good fresh squeezed data juice
    submitQueryAndFetchResults();
    updateDataLayer(e);
  }
}


/*-- AJAX submissions --*/
function submitQueryAndFetchResults(isPopState = false) {
  let page = searchParams.get('page');
  let query = (selectedProjectType ? 'project-type='+selectedProjectType : '');
  if(isPopState) {
    searchParams.delete('page');
    searchParams.set('limit', 18*page);
  }

  let concat = (query && searchParams.toString()) ? '&' : '';
  query = query + concat + searchParams.toString();
  if (projectCatalogSection) {
    projectCatalogSection.classList.add('is-loading');
  }

  [...filterCheckBoxes].forEach((el) => {
    el.classList.add('is-loading');
    el.disabled = true;
  });

  xhr.open('GET', ajaxUrl+query);
  xhr.onload = handleAjaxResponse;
  xhr.send();

  if(isPopState) {
    searchParams.set('page', page);
    searchParams.delete('limit');
  }
}

function handleAjaxResponse() {
  if(xhr.readyState !== xhr.DONE) exit;

  if(xhr.status === 200) {
    [...filterCheckBoxes].forEach((el) => {
      el.classList.remove('is-loading');
      el.disabled = false;
    });

    if(parseInt(loadMoreResultsButton.dataset.page) <= 1)
      filterResults.innerHTML = '';

    filterResults.innerHTML = filterResults.innerHTML + xhr.responseText;
    
    // Update our pagination display on load
    const totalVisibleCards = document.querySelectorAll('.project-card');
    const dataTotalResults = document.querySelector('[data-total-results]');
    if (pageCurrentDisplay && pageTotalDisplay && dataTotalResults && totalVisibleCards) {
      
      const total = dataTotalResults.dataset.totalResults;
      pageCurrentDisplay.innerHTML = totalVisibleCards.length;
      pageTotalDisplay.innerHTML = total;
      
    }
  }
  let projectGridItemCount = '';
  if (filterResults) {
    projectGridItemCount = filterResults.getElementsByClassName('_item').length;
  }
  let expectedItemCount = loadMoreResultsButton.dataset.limit * loadMoreResultsButton.dataset.page;

  loadMoreResultsButton.classList.remove('is-loading');
  console.log(projectGridItemCount, expectedItemCount);
  if((projectGridItemCount < expectedItemCount)) {
    loadMoreResultsButton.setAttribute('disabled', 'disabled');
    loadMoreResultsButton.classList.add('hidden');
    if (noMoreResults) {
      noMoreResults.classList.remove('hidden');
    }
    const pageCount = document.querySelector('[data-page-count]');
    if (pageCount) {
      pageCount.classList.add('hidden');
    }
  } else {
    loadMoreResultsButton.removeAttribute('disabled');
    if (loadMoreResultsButton.classList.contains('hidden')) {
      loadMoreResultsButton.classList.remove('hidden');
    }
    if (noMoreResults && !noMoreResults.classList.contains('hidden')) {
      noMoreResults.classList.add('hidden');
    }
    const pageCount = document.querySelector('[data-page-count]');
    if (pageCount) {
      pageCount.classList.remove('hidden');
    }
  }

  // we'll remove the current page when building links
  let projectLinkParams = new URLSearchParams(currentUrl.search);
  projectLinkParams.delete('page');

  [...document.querySelectorAll('._item')].forEach((el) => {
    if(projectLinkParams.toString()) {
      let projectLink = el.childNodes[0].dataset.href;
      el.childNodes[0].setAttribute('href', projectLink + '?' + projectLinkParams.toString());
    }
  });

  projectCatalogSection.classList.remove('is-loading');
  dataLayerProjectCards(filterResults);
  isRunning = false;
}

function handleLoadMoreResults(e) {
  isRunning = true;
  let page = parseInt(loadMoreResultsButton.dataset.page) + 1;
  loadMoreResultsButton.classList.add('is-loading');
  loadMoreResultsButton.dataset.page = page;
  searchParams.set('page', page);
  pushHistory();
  pushLocalStorage();

  // get that good fresh squeezed data juice
  submitQueryAndFetchResults();
}

function removeItem(array, item) {
  let index = array.indexOf(item);
  if (index > -1) {
    array.splice(index, 1);
  }
}

function updateDataLayer(e) {
  if(e.srcElement.dataset.filtergroup == "building-use[]") {
    if(buildingUse != '') {
      if(e.srcElement.checked) {
        buildingUse.unshift(e.srcElement.dataset.featureTitle);
      } else {
        removeItem(buildingUse, e.srcElement.dataset.featureTitle);
      }
    } else {
      if(e.srcElement.checked) {
        buildingUse.push(e.srcElement.dataset.featureTitle);
      } else {
        removeItem(buildingUse, e.srcElement.dataset.featureTitle);
      }
    }
  }

  if(e.srcElement.dataset.filtergroup == "roof-color[]") {
    if(roofColors != '') {
      if(e.srcElement.checked) {
        roofColors.unshift(e.srcElement.dataset.featureTitle);
      } else {
        roofColors.filter(item => item !== e.srcElement.dataset.featureTitle)
        removeItem(roofColors, e.srcElement.dataset.featureTitle);
      }
    } else {
      if(e.srcElement.checked) {
        roofColors.push(e.srcElement.dataset.featureTitle);
      } else {
        removeItem(roofColors, e.srcElement.dataset.featureTitle);
      }
    }
  }

  if(e.srcElement.dataset.filtergroup == "siding-color[]") {
    if(sidingColors != '') {
      if(e.srcElement.checked) {
        sidingColors.unshift(e.srcElement.dataset.featureTitle);
      } else {
        removeItem(sidingColors, e.srcElement.dataset.featureTitle);
      }
    } else {
      if(e.srcElement.checked) {
        sidingColors.push(e.srcElement.dataset.featureTitle);
      } else {
        removeItem(sidingColors, e.srcElement.dataset.featureTitle);
      }
    }
  }

  if(e.srcElement.dataset.filtergroup == "special-features[]") {
    if(specialFeatures != '') {
      if(e.srcElement.checked) {
        specialFeatures.unshift(e.srcElement.dataset.featureTitle);
      } else {
        removeItem(specialFeatures, e.srcElement.dataset.featureTitle);
      }
    } else {
      if(e.srcElement.checked) {
        specialFeatures.push(e.srcElement.dataset.featureTitle);
      } else {
        removeItem(specialFeatures, e.srcElement.dataset.featureTitle);
      }
    }
  }

  if(e.srcElement.dataset.filtergroup == "square-feet[]") {
    if(squareFeet != '') {
      if(e.srcElement.checked) {
        squareFeet.unshift(e.srcElement.dataset.featureTitle);
      } else {
        removeItem(squareFeet, e.srcElement.dataset.featureTitle);
      }
    } else {
      if(e.srcElement.checked) {
        squareFeet.push(e.srcElement.dataset.featureTitle);
      } else {
        removeItem(squareFeet, e.srcElement.dataset.featureTitle);
      }
    }
  }

  if(e.srcElement.dataset.filtergroup == "wainscot-color[]") {
    if(waintscotColors != '') {
      if(e.srcElement.checked) {
        waintscotColors.unshift(e.srcElement.dataset.featureTitle);
      } else {
        removeItem(waintscotColors, e.srcElement.dataset.featureTitle);
      }
    } else {
      if(e.srcElement.checked) {
        waintscotColors.push(e.srcElement.dataset.featureTitle);
      } else {
        removeItem(waintscotColors, e.srcElement.dataset.featureTitle);
      }
    }
  }

  dataLayer.push({
    'event': 'project list engagement',
    'e_action': 'modify filter',
    'e_label': e.srcElement.dataset.featureTitle,
    'filter_building_use': buildingUse.join('|'),
    'filter_roof_color': roofColors.join('|'),
    'filter_siding_color': sidingColors.join('|'),
    'filter_special_features': specialFeatures.join('|'),
    'filter_square_feet': squareFeet.join('|'),
    'filter_wainscot_color': waintscotColors.join('|'),
  });
}

// Function to check if the bottom of an element is in the viewport
function isElementBottomInViewport(el) {
  var rect = el.getBoundingClientRect();
  return rect.bottom <= window.innerHeight;
}

// Function to handle scroll events
function handleScroll() {
  if (loadMoreResultsButton) { 
    if (isElementBottomInViewport(loadMoreResultsButton)) {
      // Trigger lazy loading
      if (!isRunning) {
        handleLoadMoreResults();
      }
    }
  }
}

/*-- Set event listeners --*/
window.addEventListener('popstate', popHistory);

// Attach the scroll event listener
// window.addEventListener('scroll',
//   handleScroll
// );

if (loadMoreResultsButton) {
  loadMoreResultsButton.addEventListener('click', handleLoadMoreResults);  
}

if (filterProjectTypeLinks) {
  [...filterProjectTypeLinks].forEach((el) => {
    el.addEventListener('click', handleProjectTypeSelect);
  });
}

if (filterCheckBoxes) {
  [...filterCheckBoxes].forEach((el) => {
    el.addEventListener('change', handleFilterSelect);
  });      
}

if (filterResetButtons) {
  [...filterResetButtons].forEach((el) => {
    el.addEventListener('click', filterReset);
  });
}


/*-- And away we go! --*/
updateBuildingUses();
updateFilterCheckboxes();
setActiveFilter();

// Check if there are 18 or less results on initial load
const totalResults = document.querySelector('[data-total-results]');
if (totalResults) {
  const total = totalResults.dataset.totalResults;
  if (total && total <= 18) {
    if (loadMoreResultsButton) {
      loadMoreResultsButton.classList.add('hidden');
    }
    if (noMoreResults) {
      noMoreResults.classList.remove('hidden');
    }
    const pageCount = document.querySelector('[data-page-count]');
    if (pageCount) {
      pageCount.classList.add('hidden');
    }
  }
}

// Update our pagination display on load
if (pageCurrentDisplay && pageTotalDisplay && dataTotalResults && totalVisibleCards) {
    const total = dataTotalResults.dataset.totalResults;
    pageCurrentDisplay.innerHTML = totalVisibleCards.length;
    pageTotalDisplay.innerHTML = total;
}

// If we have an initial display of totals update that on load
const initialTotal = document.querySelector('[data-initial-total]');
if (initialTotal) {
  const pageTotals = document.querySelector('[data-totals]');
  if (pageTotals) {
    initialTotal.innerHTML = pageTotals.innerHTML;
  }
}

