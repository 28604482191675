const triggers = document.querySelectorAll('[data-component=accordion]');
const accordions = document.querySelectorAll('[data-accordion]');

function initAccordion(el) {
  el.addEventListener('click', () => {
    let accordion = document.querySelector('[data-accordion="' + el.dataset.target + '"]');

    if (accordion && el) {
      el.classList.toggle('is-active');
      el.classList.contains('is-active') ? accordion.classList.add('is-active') : accordion.classList.remove('is-active');

      el.setAttribute('aria-expanded', el.classList.contains('is-active') ? 'true' : 'false');
      accordion.setAttribute('aria-expanded', accordion.classList.contains('is-active') ? 'true' : 'false');
    }
  });
}

if (triggers && accordions) {
  [...triggers].forEach((el) => {
    initAccordion(el);
  });
}

