setTimeout(() => {
  const xhr = new XMLHttpRequest();
  const footer = document.getElementById('footer');
  if (footer) {
    const serviceAreaForm = footer.querySelector('[data-return-id="service-area"]');
    const serviceAreaZipInput = footer.querySelector('[name="zip"]');
    const serviceAreaResult = document.querySelector('[data-return-destination-footer="service-area"]');
    const serviceAreaReset = document.querySelector('[data-location-reset-footer]');

    let dataLayer = window.dataLayer || [];

    if (serviceAreaForm){
      serviceAreaForm.addEventListener("submit", function(e){
        e.preventDefault();

        xhr.open('GET', serviceAreaForm.action + '?zip=' + serviceAreaForm.elements.zip.value + '&cookie=' + serviceAreaForm.elements.cookie.value);

        xhr.onload = function () {
          if (xhr.readyState === xhr.DONE) {
            if (xhr.status === 200) {
              serviceAreaResult.innerHTML = xhr.responseText;
              serviceAreaForm.classList.add('is-hidden');
              serviceAreaReset.classList.remove('is-hidden');

              let dataLayerElement = serviceAreaResult.querySelector('[data-city-state');
              if (dataLayerElement) {
                dataLayer.push({
                  'event': 'find a location',
                  'e_action': 'search zip code',
                  'e_label': dataLayerElement.dataset.cityState,
                  'zip_code': dataLayerElement.dataset.search,
                  'page_footer':'true'
                });
              }
            }
          }
        };

        xhr.send();

      });

      function resetServiceAreaSearch(){
        serviceAreaForm.classList.remove('is-hidden');
        serviceAreaReset.classList.add('is-hidden');
        serviceAreaZipInput.value = '';
        serviceAreaResult.innerHTML = '';
      }
      if (serviceAreaReset) {
        serviceAreaReset.addEventListener('click', () => { resetServiceAreaSearch() });
      }
    }
  }
}, 1000);