const CatalogNavigationButton = document.querySelector('[data-component=catalogNavigation]');

// Retrieve local storage url
function getCatalogState(){
  const stateString = window.localStorage.getItem("projectCatalogState");
  return stateString ? JSON.parse(stateString) : {};
}

// Updating HREF from LocalStorage value
function updateCatalogNavigationButtonUrl(){
  const state = getCatalogState();
  if (state && state.hasOwnProperty('stateUrl')) {
    CatalogNavigationButton.href = location.origin + state.stateUrl;
  }
}

// If data-component=catalogNavigation is on the page AND if the last url contained the string '/project-catalog'
// Project Catalog also loads at /project-catalog/residential. Using the last segment is not always an option.
if (CatalogNavigationButton && document.referrer.includes('/project-catalog')){
  updateCatalogNavigationButtonUrl();
}

