
  const trigger = document.querySelector('[data-togglebutton]');
  const dealCTA = document.querySelector('[data-component=dealsToggle]');

  function initDealsToggle(el) {
    if (el) {
      el.addEventListener('click', () => {
        if (dealCTA) {
          if (dealCTA.hasAttribute('data-toggleoff')) {
            dealCTA.removeAttribute('data-toggleoff');
            dealCTA.setAttribute('data-toggleon', '');
          } else {
            dealCTA.removeAttribute('data-toggleon');
            dealCTA.setAttribute('data-toggleoff', '');
          }
        }
      });
    }
  }

  initDealsToggle(trigger);

