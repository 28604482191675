import smoothScrollTo from '../utils/smoothScrollTo';

const triggersQuery = '[data-component=smoothScrollTrigger]';
const container = document.querySelector('#main');

// need to defer this since careers map button scroll triggers are added dynamically
if (container) {
  function handleTriggerTarget(trigger) {
    let target = document.querySelector('[data-target="' + trigger.dataset.trigger + '"]');
    let offset = parseInt(trigger.dataset.offset);
    smoothScrollTo(target, offset);
  }
  container.addEventListener('click', function (e) {
    if (e.target.matches(triggersQuery)) {
      e.preventDefault();
      handleTriggerTarget(e.target);
    }
  });
}
