let dataLayer = window.dataLayer || [];
let destination = false;

let googleMap = null;
let markers = [];
let markerKeys = [];
let infoWindows = [];

const locationDetailMap = document.querySelector('[data-component="locationMap"]');
const aside = locationDetailMap ? locationDetailMap.querySelector('aside') : null;

const mapStyle = [
  {
    "featureType": "water",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#d3d3d3"
      }
    ]
  },
  {
    "featureType": "transit",
    "stylers": [
      {
        "color": "#808080"
      },
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "visibility": "on"
      },
      {
        "color": "#b3b3b3"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#ffffff"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "visibility": "on"
      },
      {
        "color": "#ffffff"
      },
      {
        "weight": 1.8
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#d7d7d7"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "visibility": "on"
      },
      {
        "color": "#ebebeb"
      }
    ]
  },
  {
    "featureType": "administrative",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#a7a7a7"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#ffffff"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#ffffff"
      }
    ]
  },
  {
    "featureType": "landscape",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "visibility": "on"
      },
      {
        "color": "#efefef"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#696969"
      }
    ]
  },
  {
    "featureType": "administrative",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "visibility": "on"
      },
      {
        "color": "#737373"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#d6d6d6"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {},
  {
    "featureType": "poi",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#dadada"
      }
    ]
  }
];

if (locationDetailMap) { 

    function initializeMap() {
        setTimeout(() => {
          const map = document.querySelector('.gm-map');
          const mapId = map.getAttribute('id');
          googleMap = googleMaps.getMap(mapId);
          buildMap(googleMap);
        }, 2500);
        const blocker = document.querySelector('.map-blocker');
        setTimeout(() => {
          blocker.classList.add('is-hidden');
        }, 2800);
    }

    function buildMap(googleMap) {
        googleMap.styles(mapStyle);
        setUpMarkers();
    }

    function setUpMarkers() {
        markers = Object.values(googleMap._markers);
        markerKeys = Object.keys(googleMap._markers);
    }

    function centerMap(latLng, googleMap) {
        googleMap.zoom(9).center(latLng);
    }

    function isGoogleMapsLoaded() {
        const intervalId = setInterval(function () {
        if (typeof googleMaps === 'undefined') {
            // Continue checking after 500ms
        } else {
            clearInterval(intervalId); // Clear the interval once googleMaps is defined
            initializeMap();
        }
        }, 500);
    }

    googleMaps.init();
    isGoogleMapsLoaded();
}

const locationMapSidebar = document.querySelector('.location-map-sidebar');
if (locationMapSidebar) {
  const viewMore = document.getElementById('map-more');
  if (viewMore) {
    viewMore.addEventListener('click', function() {
      locationMapSidebar.classList.toggle('is--expanded');
      viewMore.textContent = locationMapSidebar.classList.contains('is--expanded') ? 'See Less' : 'See More';
    });
  }
}

const typesMore = document.getElementById('project-types-more');
if (typesMore) {
  typesMore.addEventListener('click', function() {
    const types = document.querySelector('.location-detail-types');
    types.classList.toggle('is--expanded');
    typesMore.textContent = types.classList.contains('is--expanded') ? 'See Less Building Types' : 'See More Building Types';
  });
}
