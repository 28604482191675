import download from './download';

export default function () {

  /*--------------------------------------------------------/

    RIGHT-CLICK TO DOWNLOAD BG IMG IN SLIDE

    - Adapted from https://codepen.io/SitePoint/pen/MYLoWY.
    - Maintained author's markup structure for future mods.

  /*-------------------------------------------------------*/

  /*------------------------/
    Vars
  /*-----------------------*/

  var safari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/),
    iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream,
    projectPage = window.location.href.indexOf('projects') > -1, // Only target project sliders
    activeSlide,
    imgSrc,
    imgName,
    menu,
    menuState = 0,
    ul,
    li,
    a,
    fadeTime = 125; // Match trans prop .context-menu _swiperDownloadMenu.scss


  /*------------------------/
    Funcs
  /*-----------------------*/

  // Create and show menu
  function createMenu(onClickFunc=downloadIMG)
  {
    if(menuState !== 1)
    {
      menuState = 1;

      imgSrc = activeSlide.getAttribute('data-src-large');
      imgName = imgSrc.replace(/^.*[\\\/]/, '');

      menu = document.createElement('nav');
      menu.id = 'context-menu';
      menu.className = 'context-menu';

      ul = document.createElement('ul');
      ul.className = 'context-menu__items';

      li = document.createElement('li');
      li.className = 'context-menu__item';

      a = document.createElement('a');
      a.className = 'context-menu__link';
      a.innerHTML = 'DOWNLOAD IMAGE';
      a.onclick = onClickFunc;

      menu.append(ul);
      ul.append(li);
      li.append(a);

      document.body.appendChild(menu);

      // Fade in menu
      window.getComputedStyle(menu).opacity;
      menu.style.opacity = '1';
    }
  }

  // Remove menu
  function removeMenu()
  {
    if(menuState !== 0)
    {
      menuState = 0;

      // Fade out menu
      menu.style.opacity = '0';

      setTimeout(function(){ menu.remove(); }, fadeTime);
    }
  }

  // Download image to file system
  function downloadIMG()
  {
    var x = new XMLHttpRequest();

    // https://stackoverflow.com/questions/43345708/xmlhttprequest-and-s3-cors-error - Broswers Caches and CORS.
    imgSrc += "?"+(new Date()).getTime();

    // imgSrc and imgName set in createMenu().
    // Temp herokuapp link used to bypass CORS.
    x.open('GET', imgSrc, true);

    x.responseType = 'blob';

    x.onload = function(e) { download(x.response, imgName, 'image/jpeg'); }

    x.send();
  }

  // Open image in window for download
  function downloadWin()
  {
    removeMenu();

    window.open(imgSrc);
  }


  /*------------------------/
    Browser logic
  /*-----------------------*/

  if(safari && iOS && projectPage) // Mobile Safari
  {
    /*------------------------/
      Vars
    /*-----------------------*/

    var onLongTouch,
      timer,
      touchDuration = 1000,
      swipperWrapper = document.getElementsByClassName('swiper-wrapper')[0];


    /*------------------------/
      Listeners
    /*-----------------------*/

    swipperWrapper.addEventListener('touchstart', initDownload, false);
    swipperWrapper.addEventListener('touchend', exitDownload, false);


    /*------------------------/
      Funcs
    /*-----------------------*/

    function initDownload()
    {
      removeMenu();

      timer = setTimeout(onLongTouch, touchDuration);
    }

    function exitDownload()
    {
        if(timer) { clearTimeout(timer); }
    }

    onLongTouch = function() {
      activeSlide = document.getElementsByClassName('swiper-slide-active')[0];
      imgSrc = activeSlide.getAttribute('data-src-large');
      createMenu(downloadWin);
    }
  }
  else if(projectPage) // All other browsers
  {
      /*------------------------/
      Vars
    /*-----------------------*/

    var clickCoords,
      clickCoordsX,
      clickCoordsY,
      menuWidth,
      menuHeight,
      menuPosition,
      menuPositionX,
      menuPositionY,
      windowWidth,
      windowHeight;


    /*------------------------/
      Funcs
    /*-----------------------*/

    // Get menu position
    function getPosition(e)
    {
      var posx = 0, posy = 0;

      if(!e) var e = window.event;

      if(e.pageX || e.pageY)
      {
        posx = e.pageX;
        posy = e.pageY;
      }
      else if (e.clientX || e.clientY)
      {
        posx = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
        posy = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
      }

      return {
        x: posx,
        y: posy
        }
    }

    // Position menu
    function positionMenu(e)
    {
      clickCoords = getPosition(e);
      clickCoordsX = clickCoords.x;
      clickCoordsY = clickCoords.y;

      menuWidth = menu.offsetWidth;
      menuHeight = menu.offsetHeight;

      windowWidth = window.innerWidth;
      windowHeight = window.innerHeight;

      if((windowWidth - clickCoordsX) < menuWidth)
      {
        menu.style.left = windowWidth - menuWidth + 'px';
      }
      else
      {
        menu.style.left = clickCoordsX + 'px';
      }

      if((windowHeight - clickCoordsY) < menuHeight)
      {
        menu.style.top = windowHeight - menuHeight + 'px';
      }
      else
      {
        menu.style.top = clickCoordsY + 'px';
      }
    }

    /*------------------------/
      Listeners
    /*-----------------------*/

    window.onkeyup = function(e)
    {
      if (e.keyCode === 27) removeMenu();
    }

    window.onresize = function(e)
    {
      removeMenu();
    };

    document.addEventListener('click', function(e)
    {
      if(e.target == menu)
      {
        e.preventDefault();
      }
      else
      {
        removeMenu();
      }
    });

    document.addEventListener('contextmenu', function(e)
    {
      activeSlide = document.getElementsByClassName('swiper-slide-active')[0];

      if(e.target == activeSlide)
      {
        e.preventDefault();

        if(menuState == 1)
        {
          // Remove menu, allowing time for fade out
          removeMenu();
          setTimeout(function() { createMenu(); positionMenu(e); }, fadeTime);
        }
        else
        {
          createMenu();
          positionMenu(e);
        }
      }
      else
      {
        removeMenu();
      }

    }, false);
  }

}
