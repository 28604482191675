
let fullScreenTriggers = document.querySelectorAll('[data-fullScreen]');
let dataLayer = window.dataLayer || [];

if (fullScreenTriggers) {
  
  Array.from(fullScreenTriggers, function(el) {
    el.addEventListener('click', function() {
      let trigger = el;
      let triggerText = trigger.querySelector('span');
      let target = document.querySelector('[data-target='+trigger.dataset.trigger+']');
      let activeSwiperSlide = target.querySelector('.swiper-slide-active');

      target.classList.add('is-fullscreen');
      Array.from(fullScreenTriggers, function(el) {
        triggerText.textContent = el.dataset.closeText;
      });

      if(activeSwiperSlide) {
        let activeSwiperSlideStyles = activeSwiperSlide.currentStyle || window.getComputedStyle(activeSwiperSlide, false);
        let activeSwiperSlideImage = activeSwiperSlideStyles.backgroundImage.slice(4, -1).replace(/"/g, "");
        let filename = activeSwiperSlideImage.substring(activeSwiperSlideImage.lastIndexOf('/')+1);

        dataLayer.push({
          'event': 'photo carousel',
          'e_action': 'expand image',
          'e_label': filename
        });
      }

      if(!document.fullscreenElement) {
        target.requestFullscreen().catch(err => {
          console.log('Request Full Screen Error');
          triggerText.textContent = trigger.dataset.openText;
          target.classList.remove('is-fullscreen');

          forEach(fullScreenTriggers, function(el) {
            let triggerText = el.querySelector('span');
            triggerText.textContent = el.dataset.openText;
          });

        });
      } else {
        triggerText.textContent = trigger.dataset.openText;
        document.exitFullscreen();
      }
    });
  });

  document.addEventListener("fullscreenchange", (event) => {
    if (!document.fullscreenElement) {
      let target = document.querySelector('.is-fullscreen');
      target.classList.remove('is-fullscreen');

      Array.from(fullScreenTriggers, function(el) {
        let triggerText = el.querySelector('span');
        triggerText.textContent = el.dataset.openText;
      });
    }
  });
}
