export default (
  function dataLayerProjectCards(projectGrid) {

    let dataLayer = window.dataLayer || [];
    let projectCards = document.querySelectorAll('[data-component="projectCard"]');
    let impressions = [];

    [...projectCards].forEach((projectCard) => {
      //projectCard.setAttribute('data-layer-added', 'true');
      let index = [...projectGrid.children].indexOf(projectCard) + 1;
      let project = projectCard.querySelector('[data-action="project"]');

      impressions.push({
        'name': project.dataset.layerName,
        'brand': 'morton',
        'category': project.dataset.layerBuildingUse,
        'variant': project.dataset.layerLocation,
        'list': project.dataset.layerProjectType,
        'position': index
      });

    });

    dataLayer.push({
      'event':'project impressions',
      'e_action':'view thumbnails',
      'ecommerce': {
        'impressions': impressions
      }
    });

  }
);
