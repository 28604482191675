import dataLayerProjectCards from '../utils/dataLayerProjectCards';

const submitToUrl = '/ajax/relatedProjects?';
const xhr = new XMLHttpRequest();
const projectResults = document.querySelector('[data-component=loadMoreProjects]');
const loadMoreResultsButton = document.querySelector('[data-append-projects]');

if (projectResults && loadMoreResultsButton) {
  

  function submitQueryAndFetchResults(url, query, offset) {
    xhr.open('GET', url + query);

    xhr.onload = function () {
      if (xhr.readyState === xhr.DONE) {
        if (xhr.status === 200) {
          let currentResults = projectResults.innerHTML;
          projectResults.innerHTML = currentResults + xhr.responseText;
        }
      }
      loadMoreResultsButton.removeAttribute('disabled');

      let projectCards = document.querySelectorAll('[data-component="projectCard"]:not([data-layer-added])');

      dataLayerProjectCards(projectResults);

      let resultCount = projectResults.getElementsByTagName('article').length;

      if (resultCount == loadMoreResultsButton.dataset.projectCount) {
        loadMoreResultsButton.disabled = 'disabled';
        loadMoreResultsButton.innerHTML = 'No More Results';
      }
    };

    xhr.send();
  }

  function handleLoadMoreResults(e) {
    let paramsString = window.location.search;
    let searchParams = new URLSearchParams(paramsString);
    let clickedButton = this;
    let loadPage = parseInt(clickedButton.dataset.page) + 1;
    clickedButton.dataset.page = loadPage;
    searchParams.set('page', loadPage);

    if (clickedButton.dataset.relatedTo) {
      let relatedTo = parseInt(clickedButton.dataset.relatedTo);
      searchParams.set('relatedTo', relatedTo);
    }

    if (clickedButton.dataset.categoryId) {
      let categoryId = parseInt(clickedButton.dataset.categoryId);
      searchParams.set('fields[project-type][]', categoryId);
    }

    let page = parseInt(clickedButton.dataset.page);
    searchParams.set('page', page);

    let limit = parseInt(clickedButton.dataset.limit);
    searchParams.set('limit', limit);

    submitQueryAndFetchResults(submitToUrl, searchParams, true);
    loadMoreResultsButton.setAttribute('disabled', 'disabled');
  }

  loadMoreResultsButton.addEventListener('click', handleLoadMoreResults);

}