const submitToUrl = '/ajax/blog?';
const xhr = new XMLHttpRequest();
const blogResults = document.querySelector('[data-component=blogPosts]');
let loadMoreResultsButtonBlog = '';
if (blogResults) {
  loadMoreResultsButtonBlog = document.querySelector('[data-append-blog]');
}
const pageUrl = window.location.pathname;

function submitQueryAndFetchResults(url, query, offset) {
  if (xhr && blogResults) {
    
    xhr.open('GET', url + query);

    xhr.onload = function () {
      if (xhr.readyState === xhr.DONE) {
        if (xhr.status === 200) {
          let currentResults = blogResults.innerHTML;
          blogResults.innerHTML = currentResults + xhr.responseText;
        }
      }
      if (loadMoreResultsButtonBlog) {
        loadMoreResultsButtonBlog.removeAttribute('disabled');
      }
    };

    xhr.send();
  }
}

function loadMoreButtonReset(e) {
  if (loadMoreResultsButtonBlog) {
    loadMoreResultsButtonBlog.dataset.page = 2;
  }
}

function handleLoadMoreResults(e) {
  if (loadMoreResultsButtonBlog) {

    let paramsString = window.location.search ? window.location.search : '';
    let searchParams = new URLSearchParams(paramsString);
    let clickedButton = this;
    let loadPage = parseInt(clickedButton.dataset.page) + 1;

    clickedButton.dataset.page = loadPage;
    searchParams.set('page', loadPage);

    window.history.pushState("Blog Page", "Blog Page", pageUrl + '?' + searchParams);

    submitQueryAndFetchResults(submitToUrl, searchParams, true);
    loadMoreResultsButtonBlog.setAttribute('disabled', 'disabled');
  }
}

if (loadMoreResultsButtonBlog) {
  loadMoreResultsButtonBlog.addEventListener('click', handleLoadMoreResults);
}
