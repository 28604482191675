import addListener from '../utils/addListener';
import stickybits from '../../vendor/node_modules/stickybits/dist/stickybits.min';

const stickyElements = document.querySelectorAll('[data-component="sticky"]');
const stickyFilter = document.querySelectorAll('[data-sticky-filter]');
const offsetNavigationHeight = document.querySelector('.navbar.global-header').offsetHeight;
const breakpoints_mobile = '(max-width: 599px)';

[...stickyElements].forEach((stickyElement) => {
  let offset = stickyElement.dataset.stickyOffset ? stickyElement.dataset.stickyOffset : 0;

  stickybits( stickyElement, {
    useStickyClasses: true,
    stickyBitStickyOffset: stickyElement.dataset.stickyOffsetNav ? offsetNavigationHeight : offset,
    verticalPosition: stickyElement.dataset.stickyPosition ? stickyElement.dataset.stickyPosition : 'top'
  });
});

addListener(breakpoints_mobile, (query) => {
  if (query.matches) {
    [...stickyFilter].forEach((stickyElement) => {
      stickybits(stickyElement).cleanup();
    });
  } else {
    [...stickyFilter].forEach((stickyElement) => {
      stickybits( stickyElement, {
        useStickyClasses: true,
        stickyBitStickyOffset: stickyElement.dataset.stickyPosition
      });
    });
  }
});
