
  const triggers = document.querySelectorAll('[data-filtertrigger]');
  const filterCheckedBoxes = document.querySelectorAll('[data-filtertarget] input:checked');

  function initFilterAccordion(el) {
    el.addEventListener('click', () => {
      let target = el.nextElementSibling;

      el.classList.toggle('is-active');
      el.classList.contains('is-active') ? target.classList.add('is-active') : target.classList.remove('is-active');

      el.setAttribute('aria-expanded', el.classList.contains('is-active') ? 'true' : 'false');
      target.setAttribute('aria-expanded', target.classList.contains('is-active') ? 'true' : 'false');
    });
  }

  if (triggers) {
    [...triggers].forEach((el) => {
      initFilterAccordion(el);
    });
  }

  if (filterCheckedBoxes) {
    [...filterCheckedBoxes].forEach((el) => {
      let filterItemTriggers = document.querySelectorAll('[data-filtertrigger="' + el.dataset.filtergroup + '"]');

      [...filterItemTriggers].forEach((trigger) => {
        if (!trigger.classList.contains('is-active')) {
          trigger.click();
        }
      });
    });
  }

