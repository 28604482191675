const modal = require('./modal');

const xhr = new XMLHttpRequest();
const serviceAreaZipSearch = document.querySelector('[data-component=serviceAreaZipSearch]');
let dataLayer = window.dataLayer || [];

if (serviceAreaZipSearch) {
  serviceAreaZipSearch.addEventListener("submit", function(e){
    e.preventDefault();

    xhr.open('GET', serviceAreaZipSearch.action + '?zip=' + serviceAreaZipSearch.elements.zip.value + '&cookie=' + serviceAreaZipSearch.elements.cookie.value);

    xhr.onload = function () {
      if (xhr.readyState === xhr.DONE) {
        if (xhr.status === 200) {
          serviceAreaZipSearch.innerHTML = xhr.responseText;
          let dataLayerElement = serviceAreaZipSearch.querySelector('[data-city-state');

          dataLayer.push({
            'event': 'find a location',
            'e_action': 'search zip code',
            'e_label': dataLayerElement.dataset.cityState,
            'zip_code': dataLayerElement.dataset.search,
            'page_footer':'true'
          });

          modal.handleTriggerTarget(serviceAreaZipSearch);

        }
      }
    };

    xhr.send();

  });
}
