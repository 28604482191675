const bodyScrollLock = require('../../vendor/node_modules/body-scroll-lock/lib/bodyScrollLock.min');
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;
const clearAllBodyScrollLocks = bodyScrollLock.clearAllBodyScrollLocks;
const body = document.querySelector('body');
const nav = document.querySelector('.navigationVertical');
const tertiary = document.querySelector('._tertiary');
const dropdown = tertiary.querySelector('.dropdown');

const page = document.querySelector('html');
const triggers = document.querySelectorAll('[data-component=triggerTarget]');

document.body.addEventListener( 'click', function ( event ) {
  if(event.srcElement.dataset.component == 'triggerTarget') {
    handleTriggerTarget(event.srcElement);
  };
});

function handleTriggerTarget(trigger) {
  let target = document.querySelector('[data-target="' + trigger.dataset.trigger + '"]');

  if(trigger.hasAttribute('data-prevent-self-toggle')) {

  } else if (trigger.dataset.trigger == 'project-menu' ) {
    const projectMenuTriggers = document.querySelectorAll('[data-trigger="project-menu"]');
    [...projectMenuTriggers].forEach((el) => {
      if (el.classList.contains('is-active')) {
        el.classList.remove('is-active');
      }
    });
  } else {
    trigger.classList.toggle('is-active');
  }

  if(trigger.hasAttribute('data-is-nav')) {
    nav.classList.toggle('no-scroll');
  }

  target.classList.toggle('is-active');

  if(trigger.hasAttribute('data-freeze')) {
    bodyScrollLock.disableBodyScroll(target);
  }

  if(trigger.hasAttribute("data-unfreeze")) {
    bodyScrollLock.enableBodyScroll(target);
  }
}

if(dropdown) {
  dropdown.addEventListener('click', function(event) {
    event.preventDefault();
    dropdown.classList.toggle('is-active');
  });
}
